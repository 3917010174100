import React from "react"
import Shows from "../components/Shows"
import "../styles/styles.scss"
import Div100vh from "react-div-100vh"
import Helmet from "react-helmet"
import OG from "../images/og.jpg"
import star from "../images/star.png"
import { useEffect, useState } from "react"
export default function Home() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => setLoading(true), 700)
  }, [])
  return (
    <>
      <Helmet
        title={"Yung Lean — Stardust"}
        meta={[
          {
            name: "apple-mobile-web-app-capable",
            content: "yes",
          },
          {
            name: "viewport",
            content:
              "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
          },
          {
            name: `description`,
            content: "Stardust — available now on all streaming platforms",
          },
          {
            property: `og:title`,
            content: "Yung Lean — Stardust",
          },
          {
            property: `og:description`,
            content: "Stardust — available now on all streaming platforms",
          },
          {
            property: `og:image`,
            content: `${OG}`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:image:src`,
            content: `https://yunglean.com${OG}`,
          },
          {
            name: `twitter:creator`,
            content: "Yung lean",
          },
          {
            name: `twitter:title`,
            content: "Yung Lean — Stardust",
          },
          {
            name: `twitter:description`,
            content: "Stardust — available now on all streaming platforms",
          },
        ]}
      >
        <body className="presaleB"></body>
      </Helmet>
      <Div100vh>
        <div className="container presale_cont">
          <div className="links presale">
            <p>
              <div class="cta">
                <span>
                  <img class="star" src={star} alt="" />
                </span>
                CLICK YOUR DATE BELOW TO ENTER PRESALE{" "}
                <span>
                  <img class="star" src={star} alt="" />
                </span>{" "}
              </div>
              (ENTER summertimeblood IF REQUESTED)
            </p>
            <a
              href="https://protect-us.mimecast.com/s/ksUkC68j8rIVyDm0C5430x?domain=gla.lnk.to"
              target="_blank"
            >
              November 4 — Cologne, DE — Carlswerk Victoria
            </a>
            <a
              href="https://apps.ticketmatic.com/widgets/democrazy/addtickets?event=503583170411#!/addtickets"
              target="_blank"
            >
              November 5 — Gent, BE — Vooruit
            </a>
            <a
              href="https://www.ticketmaster.nl/event/280753?CL_ORIGIN=ORIGIN2"
              target="_blank"
            >
              November 7 — Utrecht, NL — TivoliVredenburg - Ronda
            </a>
            <a
              href="https://dice.fm/partner/goodlive-artists/event/kvooo-yung-lean-8th-nov-columbiahalle-berlin-tickets?dice_source=web&dice_medium=organic&dice_campaign=Goodlive+Artists&_branch_match_id=975047657478252974&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz8nMy9ZLyUxO1UvL1S8yMzQwME9KTU60sAQAT9sDPiEAAAA%3D"
              target="_blank"
            >
              November 8 — Berlin, DE — Columbiahalle
            </a>
            <a
              href="https://goingapp.pl/wydarzenie/yung-lean-warszawa/listopad-2022"
              target="_blank"
            >
              November 9 — Warsaw, PL — Hall Expo XXI Hall 1
            </a>
            <a href="https://link.dice.fm/c1305229f4ee" target="_blank">
              November 11 — Paris, FR — Le Trianon
            </a>
            <a
              href="https://www.ticketmaster.co.uk/event/1F005C8792911750?did=uklnfan2ps"
              target="_blank"
            >
              November 13 — Manchester, UK — Manchester Academy
            </a>
            <a
              href="https://www.ticketweb.uk/event/yung-lean-o2-academy-brixton-tickets/12001275?pl=metropolis"
              target="_blank"
            >
              November 14 — London, UK — O2 Academy Brixton
            </a>
            <a
              href="https://www.ticketmaster.ie/venueartist/198239/1913465?did=tsitra"
              target="_blank"
            >
              November 16 — Dublin, IE — Olympia Theatre
            </a>
            <a
              href="https://www.ticketmaster.ca/event/31005C879EEA3903?lang=en-ca&brand=mtelus"
              target="_blank"
            >
              November 30 — Montreal, QC — MTELUS
            </a>
            <a
              href="https://www.ticketmaster.ca/event/10005CA4C7E34B1C"
              target="_blank"
            >
              December 1 — Toronto, ON — Rebel
            </a>
            <a href="https://link.dice.fm/YungLean_Night1" target="_blank">
              December 3 — Queens, NY — Knockdown Center
            </a>
            <a href="https://link.dice.fm/YungLean_Night2" target="_blank">
              December 4 — Queens, NY — Knockdown Center
            </a>
            <a
              href="https://www.axs.com/events/430945/yung-lean-tickets"
              target="_blank"
            >
              December 6 — Philadelphia, PA — Franklin Music Hall
            </a>
            <a
              href="https://www.axs.com/events/431199/yung-lean-tickets"
              target="_blank"
            >
              December 7 — Chicago, IL — Concord Music Hall
            </a>
            <a
              href="https://www.axs.com/events/431370/yung-lean-tickets?skin=goldenvoice"
              target="_blank"
            >
              December 9 — San Francisco, CA — The Warfield
            </a>
            <a
              href="https://www.axs.com/events/430910/yung-lean-tickets"
              target="_blank"
            >
              December 10 — Los Angeles, CA — The Novo
            </a>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            background: "#fff",
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: loading ? 0 : 1,
            transition: "transition: opacity 10s ease-in-out",
          }}
          className="fade"
        ></div>
      </Div100vh>
    </>
  )
}
